import React from 'react';
import { graphql } from 'gatsby';
import { ItemsListIndexPage, PageHero, PageWithSubNav } from 'docComponents';
import pageHeroData from '../../../data/pages/resources.yml';

const IndexPage = ({ data }) => {
  return (
    <PageWithSubNav pageType="design" title="Code Variables" subnav="resources">
      <PageHero
        heroData={pageHeroData}
        tierTwo="Code Variables"
        tierThree="Overview"
      />

      <ItemsListIndexPage
        data={data}
        tierTwo="code-variables"
        listType="word"
        listLayout="2"
      />
    </PageWithSubNav>
  );
};

export default IndexPage;

export const query = graphql`
  query variablesIndexQuery($tierOne: String = "Resources") {
    ...indexList
  }
`;
